import cntl from "cntl";
import PropTypes from "prop-types";
import { IconTriangleFilled } from "@tabler/icons-react";
import { NAV_ITEM_BASE_VARIANTS } from "../../constants";
import { ContentNavItem, NavItemBase } from "../../atoms";

const propTypes = {
  items: PropTypes.array,
  handleToggle: PropTypes.func,
  toggleIndex: PropTypes.object,
};

function NavItemExpandible({ items, handleToggle, toggleIndex }) {
  const liCn = () => cntl`
    list-none
  `;

  const navItemBaseCn = ({ currentIsOpen }) => cntl`
    overflow-hidden
    transition-[max-height]
    ${currentIsOpen ? cntl`max-h-[21rem]` : cntl`max-h-0`}
  `;

  return (
    <>
      {items?.map(({ text, icon, children }, parentIndex) => (
        <li id={`parent-list-${parentIndex}`} key={parentIndex} className={liCn()}>
          {text?.length > 0 ? (
            <SmallNavItemExpandible
              text={text}
              icon={icon}
              index={parentIndex}
              onClick={(parentIndex) => handleToggle({ parentIndex, childrenIndex: -1 })}
              isOpen={!toggleIndex[parentIndex]?.includes(-1)}
            />
          ) : null}
          <ul
            className={navItemBaseCn({
              currentIsOpen: !toggleIndex[parentIndex]?.includes(-1),
            })}
          >
            {children?.map((props, childrenIndex) => {
              if (props?.children) {
                return (
                  <div key={childrenIndex} id={`child-list-${parentIndex}-${childrenIndex}`}>
                    <MainNavItemExpandible
                      index={childrenIndex}
                      parentIndex={parentIndex}
                      onClick={(parentIndex, index) => handleToggle({ parentIndex, childrenIndex: index })}
                      isOpen={toggleIndex[parentIndex]?.includes(childrenIndex)}
                      {...props}
                    />
                    <ul
                      className={navItemBaseCn({
                        currentIsOpen: toggleIndex[parentIndex]?.includes(childrenIndex),
                      })}
                    >
                      {props?.children?.map((props, index) => {
                        return <NavItemBase key={index} variant={NAV_ITEM_BASE_VARIANTS.sub_main} {...props} />;
                      })}
                    </ul>
                  </div>
                );
              } else {
                return <NavItemBase key={childrenIndex} {...props} />;
              }
            })}
          </ul>
        </li>
      ))}
    </>
  );
}

const MainNavItemExpandible = ({
  text,
  icon,
  index,
  isOpen,
  onClick,
  className,
  parentIndex,
  positionTriangleIcon,
  isVisibleTriangleIcon,
}) => {
  const mainNavItemExpandibleCn = () => cntl`
    flex
    flex-col
    ${className ? className : undefined}
  `;

  const contentNavItemCn = () => cntl`
    py-1
    px-2
    transition
    rounded-md
    cursor-pointer
    hover:bg-gray-light-4
    dark:hover:bg-gray-dark-4
  `;

  return (
    <div className={mainNavItemExpandibleCn()} onClick={() => onClick(parentIndex, index)}>
      <ContentNavItem
        text={text}
        icon={icon}
        isOpen={isOpen}
        className={contentNavItemCn()}
        positionTriangleIcon={positionTriangleIcon}
        isVisibleTriangleIcon={isVisibleTriangleIcon}
      />
    </div>
  );
};

const SmallNavItemExpandible = ({ index, text, isOpen, onClick }) => {
  const smallNavItemExpandibleCn = () => cntl`
    flex
    flex-col
  `;

  const headerCn = () => cntl`
    py-1
    px-2
    flex
    gap-x-1.5
    transition
    rounded-md
    items-center
    cursor-pointer
    hover:bg-gray-light-4
    dark:hover:bg-gray-dark-4
  `;

  const textCn = () => cntl`
    text-xs
    transition
    font-medium
    text-gray-light-10
    dark:text-gray-dark-10
    hover:text-gray-light-11
    dark:hover:text-gray-dark-11
  `;

  const iconCn = () => cntl`
    w-2
    h-2
    text-gray-light-10
    dark:text-gray-dark-10
    transition-transform
    ${isOpen ? cntl`rotate-180` : cntl`rotate-90`}
  `;

  return (
    <div className={smallNavItemExpandibleCn()} onClick={() => onClick(index)}>
      <div className={headerCn()}>
        {text ? <div className={textCn()}>{text}</div> : null}
        <IconTriangleFilled className={iconCn()} />
      </div>
    </div>
  );
};

NavItemExpandible.propTypes = propTypes;
export default NavItemExpandible;
