import cntl from "cntl";
import Button from "./Button";
import PropTypes from "prop-types";
import { BUTTON_VARIANTS } from "../../constants/index";

const propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf([
    BUTTON_VARIANTS.soft,
    BUTTON_VARIANTS.solid,
    BUTTON_VARIANTS.ghost,
    BUTTON_VARIANTS.outline,
  ]),
};

function ButtonPrimary({ variant = BUTTON_VARIANTS.solid, className, ...props }) {
  const solidCn = () => cntl`
    text-white
    bg-primary-light-9
    dark:bg-primary-dark-9
    hover:bg-primary-light-10
    dark:hover:bg-primary-dark-10
    disabled:bg-gray-lightAlpha-3
    dark:disabled:bg-gray-darkAlpha-3
    disabled:text-gray-lightAlpha-8
    dark:disabled:text-gray-darkAlpha-8
    focus:bg-primary-light-10
    dark:focus:bg-primary-dark-10
    focus:outline
    focus:outline-2
    focus:outline-offset-2
    focus:outline-primary-lightAlpha-8
    dark:focus:outline-primary-darkAlpha-8
  `;

  const outlineCn = () => cntl`
    border
    border-primary-lightAlpha-8
    dark:border-primary-darkAlpha-8
    text-primary-lightAlpha-11
    dark:text-primary-darkAlpha-11
    bg-transparent
    hover:bg-primary-lightAlpha-2
    dark:hover:bg-primary-darkAlpha-2
    disabled:bg-gray-lightAlpha-3
    dark:disabled:bg-gray-darkAlpha-3
    disabled:text-gray-lightAlpha-11
    dark:disabled:text-gray-darkAlpha-11
    focus:bg-primary-lightAlpha-3
    dark:focus:bg-primary-darkAlpha-3
    focus:outline
    focus:outline-2
    focus:outline-offset-2
    focus:outline-primary-lightAlpha-8
    dark:focus:outline-primary-darkAlpha-8
  `;

  const softCn = () => cntl`
    text-primary-lightAlpha-11
    dark:text-primary-darkAlpha-11
    bg-primary-lightAlpha-3
    dark:bg-primary-darkAlpha-3
    hover:bg-primary-lightAlpha-4
    dark:hover:bg-primary-darkAlpha-4
    disabled:bg-gray-lightAlpha-3
    dark:disabled:bg-gray-darkAlpha-3
    disabled:text-gray-lightAlpha-8
    dark:disabled:text-gray-darkAlpha-8
    focus:bg-primary-lightAlpha-4
    dark:focus:bg-primary-darkAlpha-4
    focus:outline
    focus:outline-2
    focus:outline-offset-2
    focus:outline-primary-lightAlpha-8
    dark:focus:outline-primary-darkAlpha-8
  `;

  const ghostCn = () => cntl`
    bg-transparent
    text-primary-lightAlpha-11
    dark:text-primary-darkAlpha-11
    hover:bg-primary-lightAlpha-3
    dark:hover:bg-primary-darkAlpha-3
    disabled:bg-gray-lightAlpha-3
    dark:disabled:bg-gray-darkAlpha-3
    disabled:text-gray-lightAlpha-8
    dark:disabled:text-gray-darkAlpha-8
    focus:bg-primary-lightAlpha-4
    dark:focus:bg-primary-darkAlpha-4
    focus:outline
    focus:outline-2
    focus:outline-offset-2
    focus:outline-primary-lightAlpha-8
    dark:focus:outline-primary-darkAlpha-8
  `;

  const buttonCn = () => cntl`
    ${className ? className : undefined}
    ${
      variant === BUTTON_VARIANTS.solid
        ? solidCn()
        : variant === BUTTON_VARIANTS.outline
        ? outlineCn()
        : variant === BUTTON_VARIANTS.soft
        ? softCn()
        : variant === BUTTON_VARIANTS.ghost
        ? ghostCn()
        : null
    }
  `;

  return <Button className={buttonCn()} {...props} />;
}

ButtonPrimary.propTypes = propTypes;
export default ButtonPrimary;
