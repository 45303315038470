import { Provider } from "react-redux";
import React, { Suspense } from "react";
import { store } from "@dbox/aldys-common";
import AldysRoutes from "./routes/AldysRoutes";
import { Notification } from "@dbox/components";
import Bootstrap from "@dbox/core/components/Bootstrap";
import { NOTIFICATION_VARIANTS } from "@dbox/components/constants";

function App() {
  return (
    <Suspense fallback="loading">
      <Provider store={store}>
        <Bootstrap app={"aldys-bo"}>
          <AldysRoutes />
        </Bootstrap>
        <Notification variantsAllowed={[NOTIFICATION_VARIANTS.toast]} />
      </Provider>
    </Suspense>
  );
}

export default App;