import { Navigate, Outlet, useOutletContext } from "react-router-dom";

const ProtectedRoute = ({ isLoggedIn }) => {
  const context = useOutletContext();

  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }

  return <Outlet context={context} />;
};
export default ProtectedRoute;
