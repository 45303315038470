// Utils
import cntl from "cntl";
import { uniqueId } from "lodash";
import PropTypes from "prop-types";

// Components
import { FileUploadButton, UploadListItem } from "@dbox/components";

// Constants
import { FORMAT_ACCEPTED } from "../../constants";

const propTypes = {
  files: PropTypes.array,
  onDelete: PropTypes.func,
  isLoading: PropTypes.bool,
  fileName: PropTypes.string,
  formatAccepted: PropTypes.oneOf([FORMAT_ACCEPTED.json, FORMAT_ACCEPTED.image, FORMAT_ACCEPTED.document]),
};

const FileUploadWithListItem = ({ handleFile, isLoading, formatAccepted, files, onDelete }) => {
  const fileUploadWithListItemCn = () => cntl`
    flex
    flex-col
    gap-y-2
  `;

  const fileUploadedProps = {
    handleFile,
    formatAccepted,
  };

  return (
    <div className={fileUploadWithListItemCn()}>
      <div>
        <FileUploadButton {...fileUploadedProps} />
      </div>
      {files?.map((file, index) => (
        <UploadListItem
          key={uniqueId(`list-item-${index}-`)}
          isLoading={isLoading}
          fileName={file.name}
          onDelete={() => onDelete(index)}
        />
      ))}
    </div>
  );
};

FileUploadWithListItem.propTypes = propTypes;
export default FileUploadWithListItem;
