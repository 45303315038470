import { useEffect, useState, useMemo } from "react";
import { Pagination } from "..";
import T from "prop-types";
import { IconChevronDown, IconChevronUp, IconSelector } from "@tabler/icons-react";
import { PAGINATION_VARIANTS, SKELETON_VARIANTS } from "../constants";
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  getExpandedRowModel,
} from "@tanstack/react-table";
import cntl from "cntl";
import Skeleton from "components/molecules/skeleton/Skeleton";

const propTypes = {
  from: T.number,
  to: T.number,
  hiddenHeader: T.bool,
  columns: T.array.isRequired,
  data: T.array.isRequired,
  paginationSize: T.number,
  pageCount: T.number,
  fetch: T.func,
  total: T.number,
  className: T.string,
  isLoading: T.bool,
  settings: T.object,
  columnVisibility: T.object,
  variantPagination: T.oneOf([PAGINATION_VARIANTS.center, PAGINATION_VARIANTS.default]),
};

const Rows = ({ table, settings }) => {
  const trCn = () => cntl`
    bg-transparent
    hover:bg-gray-light-2
    hover:dark:bg-gray-dark-2
    border-b
    border-gray-light-4
    dark:border-gray-dark-4
    last:border-b-0
    text-gray-light-12
    dark:text-gray-dark-12
    ${settings?.table?.row?.height || `h-12`}
  `;

  const tdCn = () => cntl`
    flex
    items-center
  `;

  return table?.getRowModel()?.rows?.map((row) => {
    return (
      <tr key={row.id} className={trCn()}>
        {row?.getVisibleCells()?.map((cell) => {
          return (
            <td key={cell.id}>
              <div className={tdCn()}>{flexRender(cell?.column?.columnDef?.cell, cell?.getContext())}</div>
            </td>
          );
        })}
      </tr>
    );
  });
};

function DataGrid({
  to,
  data,
  from,
  total,
  fetch,
  columns,
  settings,
  pageCount,
  isLoading,
  className,
  columnVisibility,
  variantPagination,
  paginationSize = 15,
  hiddenHeader = false,
  hiddenPagination = false,
}) {
  const [sorting, setSorting] = useState([]);
  const [expanded, setExpanded] = useState({});
  const [width, setWidth] = useState(window.innerWidth);
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: paginationSize,
  });

  const thHeaderSort = ({ textAlign }) => cntl`
    flex
    gap-x-1
    font-medium
    select-none
    items-center
    cursor-pointer
    ${textAlign?.toLowerCase() === "left" ? cntl`justify-start` : cntl`justify-end`}
  `;

  const thHeaderNoSort = ({ textAlign }) => cntl`
    flex
    gap-x-1
    font-medium
    ${textAlign?.toLowerCase() === "left" ? cntl`justify-start` : cntl`justify-end`}
  `;

  const iconSortCn = () => cntl`
    w-4
    h-4
  `;

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const table = useReactTable({
    data,
    columns,
    pageCount,
    autoResetPage: false,
    manualPagination: true,
    initialState: { pageIndex: 0 },
    onSortingChange: setSorting,
    state: {
      sorting,
      expanded,
      pagination,
      columnVisibility,
    },
    onPaginationChange: setPagination,
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.children,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    defaultColumn: {
      minSize: 0,
      size: 0,
    },
  });

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  useEffect(() => {
    fetch({ pageIndex: pagination.pageIndex, pageSize: pagination.pageSize });
  }, [fetch, pagination.pageIndex, pagination.pageSize]);

  return (
    <>
      {isLoading ? (
        <Skeleton variant={SKELETON_VARIANTS.table} />
      ) : (
        <>
          {!!data?.length && (
            <div className={`flex flex-col overflow-y-auto ${className ? className : undefined}`}>
              <table className={`w-full ${settings?.table?.bgColor}`}>
                {!hiddenHeader && (
                  <thead
                    className={`h-12 text-sm text-gray-light-11 dark:text-gray-dark-11 border-b border-gray-light-6 dark:border-gray-dark-6 ${
                      settings?.table?.header?.bgColor || `bg-gray-light-2 dark:bg-gray-dark-2`
                    }`}
                  >
                    {table?.getHeaderGroups() &&
                      table?.getHeaderGroups()?.map((headerGroup) => {
                        return (
                          <tr key={headerGroup.id}>
                            {headerGroup?.headers?.map((header) => {
                              return (
                                <th
                                  key={header.id}
                                  className="text-left px-6"
                                  style={{
                                    width: header.getSize() !== 0 ? header.getSize() : undefined,
                                  }}
                                >
                                  <div
                                    {...{
                                      className: header.column.getCanSort()
                                        ? thHeaderSort({
                                            textAlign: header.column.columnDef.textHeaderAlign || "left",
                                          })
                                        : thHeaderNoSort({
                                            textAlign: header.column.columnDef.textHeaderAlign || "left",
                                          }),
                                      onClick: header.column.getToggleSortingHandler(),
                                    }}
                                  >
                                    {flexRender(header.column.columnDef.header, header.getContext())}
                                    {header.column.getCanSort() && (
                                      <div className={iconSortCn()}>
                                        {!header.column.getIsSorted() && <IconSelector className={iconSortCn()} />}
                                        {{
                                          asc: <IconChevronDown className={iconSortCn()} />,
                                          desc: <IconChevronUp className={iconSortCn()} />,
                                        }[header.column.getIsSorted()] ?? null}
                                      </div>
                                    )}
                                  </div>
                                </th>
                              );
                            })}
                          </tr>
                        );
                      })}
                  </thead>
                )}
                <tbody>
                  <Rows table={table} settings={settings} />
                </tbody>
              </table>
              {table.getPageCount() > 1 && !hiddenPagination && (
                <Pagination
                  to={to}
                  from={from}
                  total={total}
                  pageCount={pageCount}
                  variant={variantPagination}
                  paginationSize={paginationSize}
                  nextPage={() => table.nextPage()}
                  canNextPage={table.getCanNextPage()}
                  previousPage={() => table.previousPage()}
                  canPreviousPage={table.getCanPreviousPage()}
                  goToPage={(index) => table.setPageIndex(index)}
                  pageIndex={table.getState().pagination.pageIndex}
                />
              )}
            </div>
          )}
        </>
      )}
    </>
  );
}

DataGrid.propTypes = propTypes;
export default DataGrid;
