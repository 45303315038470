import cntl from "cntl";
import PropTypes from "prop-types";
import { cloneElement } from "react";
import { NavLink } from "react-router-dom";

const propTypes = {
  icon: PropTypes.node,
  to: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

function NavItemButton({ to, icon, text }) {
  const iconCn = () => cntl`
    w-3.5
    h-3.5
    text-gray-light-11
    dark:text-gray-dark-11
  `;

  const textCn = () => cntl`
    text-xs
    font-medium
    text-gray-light-11
    dark:text-gray-dark-11
    group-hover:text-gray-light-11
    dark:group-hover:text-gray-dark-11
  `;

  const navItemBtnCn = () => cntl`
    py-1
    px-2
    flex
    group
    gap-x-2
    rounded-md
    transition
    items-center
    hover:bg-gray-light-4
    dark:hover:bg-gray-dark-4
  `;

  return (
    <NavLink to={to} className={navItemBtnCn()}>
      {icon
        ? cloneElement(icon, {
            className: iconCn(),
          })
        : null}
      {text ? <div className={textCn()}>{text}</div> : null}
    </NavLink>
  );
}

NavItemButton.propTypes = propTypes;
export default NavItemButton;
