import cntl from "cntl";
import PropTypes from "prop-types";
import { TextLink } from "@dbox/components";
import { IconMail } from "@tabler/icons-react";
import { Trans, useTranslation } from "react-i18next";
import { POSITIONS } from "@dbox/components/constants";
import VerificationCodeForm from "./VerificationCodeForm";
import VerificationTitleAndSubtitle from "../atoms/VerificationTitleAndSubtitle";

const propTypes = {
  email: PropTypes.string,
  onSubmit: PropTypes.func,
  className: PropTypes.string,
  to: PropTypes.string,
  isButtonLoading: PropTypes.bool,
  onClickResendEmail: PropTypes.func,
};

function ContainerVerificationStep2({
  email,
  onSubmit,
  className,
  to,
  isButtonLoading,
  onClickResendEmail,
}) {
  const { t } = useTranslation();

  const container = () => cntl`
    px-4
    xs:px-0
    xs:w-[22.5rem]
    ${className ? className : undefined}
  `;

  const wrapperCn = () => cntl`
    flex
    gap-y-8
    flex-col
  `;

  const textLinkCn = () => cntl`
    self-center
  `;

  const resendCn = () => cntl`
    flex
    gap-x-1
    text-sm
    flex-row
    flex-wrap
    items-end
    text-gray-light-11 
    dark:text-gray-dark-11
    justify-center
  `;

  const clickCn = () => cntl`
    font-semibold
    cursor-pointer
    text-gray-light-11 
    dark:text-gray-dark-11
  `;

  const ResendEmail = () => {
    return (
      <div className={resendCn()}>
        <Trans
          i18nKey="page.verification.step2.body.resend_email"
          values={{
            clickResendEmail: t("page.verification.step2.body.click_to_resend"),
          }}
          components={{
            c: <span className={clickCn()} onClick={onClickResendEmail} />,
          }}
        />
      </div>
    );
  };

  return (
    <section className={container()}>
      <div className={wrapperCn()}>
        <VerificationTitleAndSubtitle
          icon={<IconMail />}
          title={t("page.verification.step2.title")}
          subTitle={
            <Trans
              i18nKey="page.verification.step2.sub_title"
              values={{
                email: email,
              }}
              components={{
                e: <span className="font-semibold" />,
              }}
            />
          }
        />
        <VerificationCodeForm onSubmit={onSubmit} isButtonLoading={isButtonLoading} />
        <ResendEmail />
        <TextLink
          className={textLinkCn()}
          to={to}
          iconPosition={POSITIONS.left}
          label={t("text_link.label.back_to_login")}
        />
      </div>
    </section>
  );
}

ContainerVerificationStep2.propTypes = propTypes;
export default ContainerVerificationStep2;
