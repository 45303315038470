import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ProtectedRoute from "@dbox/aldys-common/routes/ProtectedRoute";
import { getIsLoggedIn, getLoginVerificationState } from "@dbox/aldys-common/store/reducers/app.reducer";
import { Route, createBrowserRouter, createRoutesFromElements, RouterProvider, Navigate } from "react-router-dom";

// Layout
const LayoutFullScreen = React.lazy(() => import("@dbox/aldys-common/components/templates/LayoutFullScreen"));
const LayoutWithBackofficeSidebar = React.lazy(() =>
  import("@dbox/aldys-common/components/templates/LayoutWithBackofficeSidebar")
);

// Login, signup and reset passoword (page without login)
const Login = React.lazy(() => import("@dbox/aldys-common/pages/Login"));
const Signup = React.lazy(() => import("@dbox/aldys-common/pages/Signup"));
const SignupWelcome = React.lazy(() => import("@dbox/aldys-common/pages/SignupWelcome"));
const ResetPassword = React.lazy(() => import("@dbox/aldys-common/pages/ResetPassword"));
const LoginVerification = React.lazy(() => import("@dbox/aldys-common/pages/LoginVerification"));
const RequestRecoveryPassword = React.lazy(() => import("@dbox/aldys-common/pages/RequestRecoveryPassword"));

// Main
const MainProjects = React.lazy(() => import("@dbox/aldys-bo/src/pages/main/Projects"));
const MainAddOrEditProject = React.lazy(() => import("@dbox/aldys-bo/src/pages/main/AddOrEditProject"));

const AldysRoutes = () => {
  const { t } = useTranslation();
  const isLoggedIn = useSelector(getIsLoggedIn);
  const loginVerification = useSelector(getLoginVerificationState);
  const isVerificationCompleted = isLoggedIn && loginVerification?.step1 && loginVerification?.step2;

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/" element={<ProtectedRoute isLoggedIn={isVerificationCompleted} />}>
          <Route element={<LayoutWithBackofficeSidebar />}>
            <Route path="/" element={<Navigate to="projects" />} />
            <Route path="projects">
              <Route
                index
                handle={{
                  crumb: () => [
                    {
                      label: t("breadcrumbs.projects"),
                    },
                  ],
                }}
                element={
                  <Suspense fallback="Loading...">
                    <MainProjects />
                  </Suspense>
                }
              />
              <Route
                path="add"
                handle={{
                  crumb: () => [
                    {
                      to: "..",
                      label: t("breadcrumbs.projects"),
                    },
                    {
                      label: t("breadcrumbs.add_project"),
                    },
                  ],
                }}
                element={
                  <Suspense fallback="Loading...">
                    <MainAddOrEditProject />
                  </Suspense>
                }
              />
              <Route
                path="edit/:id"
                handle={{
                  crumb: () => [
                    {
                      to: "..",
                      label: t("breadcrumbs.projects"),
                    },
                    {
                      label: t("breadcrumbs.edit_project"),
                    },
                  ],
                }}
                element={
                  <Suspense fallback="Loading...">
                    <MainAddOrEditProject />
                  </Suspense>
                }
              />
            </Route>
          </Route>
        </Route>

        {/* No login */}
        <Route path="/" element={<LayoutFullScreen />}>
          <Route path="/" element={<Login />} />
          <Route path="login" element={<Login />} />
          <Route path="signup" element={<Signup />} />
          <Route path="password/reset" element={<ResetPassword />} />
          <Route path="signup/success" element={<SignupWelcome />} />
          <Route path="login/verification" element={<LoginVerification />} />
          <Route path="/password/recovery" element={<RequestRecoveryPassword />} />
        </Route>
      </>
    )
  );

  return <RouterProvider router={router} />;
};

export default AldysRoutes;
