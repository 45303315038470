import { SIZE } from "@dbox/components/constants";
import { IconSlash } from "@tabler/icons-react";
import cntl from "cntl";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const propTypes = {
  data: PropTypes.array.isRequired,
  size: PropTypes.oneOf([SIZE.xs, SIZE.sm]),
};

function Breadcrumbs({ data, size = SIZE.xs }) {
  const { t } = useTranslation("");
  const style = {
    text: {
      [SIZE.xs]: "text-xs",
      [SIZE.sm]: "text-sm",
    },
    icon: { [SIZE.xs]: "w-3 h-3", [SIZE.sm]: "w-4 h-4" },
  };

  const navCn = () => cntl`
    flex
    truncate
    max-w-lg
    flex-row
    items-center
  `;

  const slashCn = () => cntl`
    leading-none
    text-gray-dark-10
    -rotate-12
    ${style.icon[size]}
  `;

  const activeCn = () => cntl`
    font-semibold
    text-gray-lightAlpha-12
    dark:text-gray-darkAlpha-12
    ${style.text[size]}
  `;

  const pendingCn = () => cntl`
    font-medium
    text-primary-lightAlpha-11
    dark:text-primary-darkAlpha-11
    ${style.text[size]}
  `;

  const breadcrumbCn = () => cntl`
    flex
    flex-row
    items-center
  `;

  return (
    <nav className={navCn()}>
      {data
        ?.filter((breadcrumb) => breadcrumb)
        ?.map((breadcrumb, index) => {
          return (
            <div className={breadcrumbCn()} key={index}>
              {data?.length === index + 1 ? (
                <div className={activeCn()}>{t(`${breadcrumb?.label}`)}</div>
              ) : breadcrumb?.to ? (
                <NavLink to={breadcrumb?.to} className={pendingCn()}>
                  {t(`${breadcrumb?.label}`)}
                </NavLink>
              ) : (
                <div className={pendingCn()}>{breadcrumb?.label}</div>
              )}
              {data?.length !== index + 1 && <IconSlash className={slashCn()} />}
            </div>
          );
        })}
    </nav>
  );
}

Breadcrumbs.propTypes = propTypes;
export default Breadcrumbs;
