import cntl from "cntl";
import PropTypes from "prop-types";
import { ButtonGrey, ButtonPrimary } from "..";
import { useState, useMemo, cloneElement } from "react";
import { BUTTON_VARIANTS, POSITIONS, SIZE } from "../constants";
import { IconPlus } from "@tabler/icons-react";

const propTypes = {
  text: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  labelButtonGrey: PropTypes.string,
  onClickButtonGrey: PropTypes.func,
  labelButtonPrimary: PropTypes.string,
  onClickButtonPrimary: PropTypes.func,
  size: PropTypes.oneOf([SIZE.sm, SIZE.md, SIZE.lg]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

function EmptyState({
  size,
  text,
  title,
  children,
  className,
  labelButtonGrey,
  onClickButtonGrey,
  labelButtonPrimary,
  onClickButtonPrimary,
}) {
  const [textStyle, setTextStyle] = useState("");
  const [titleStyle, setTitleStyle] = useState("");
  const [gapBetweenText, setGapBetweenText] = useState("");
  const [gapChildrenAndText, setGapChildrenAndText] = useState("");

  const emptyStateWrapperCn = () => cntl`
    flex
    flex-col
    w-[22rem]
    ${gapChildrenAndText}
    ${className ? className : undefined}
  `;

  const childrenCn = () => cntl`
    flex
    flex-row
    justify-center
  `;

  const textAndButtonsCn = () => cntl`
    flex
    gap-y-6
    flex-col
  `;

  const titleCn = () => cntl`
    font-semibold
    text-gray-light-12
    dark:text-gray-dark-12
    first-letter:uppercase
    ${titleStyle}
  `;

  const textCn = () => cntl`
    text-gray-light-11
    whitespace-pre-line
    dark:text-gray-dark-11
    first-letter:uppercase
    ${textStyle}
  `;

  const titleAndTextCn = () => cntl`
    flex
    flex-col
    text-center
    ${gapBetweenText}
  `;

  const buttonsCn = () => cntl`
    flex
    gap-x-3
    flex-row
    justify-center
  `;

  useMemo(() => {
    switch (size) {
      case SIZE.sm:
        setTextStyle("text-sm");
        setTitleStyle("text-base");
        setGapBetweenText("gap-y-1");
        setGapChildrenAndText("gap-y-4");
        break;
      case SIZE.md:
        setTextStyle("text-sm");
        setTitleStyle("text-lg");
        setGapBetweenText("gap-y-2");
        setGapChildrenAndText("gap-y-5");
        break;
      case SIZE.lg:
        setTitleStyle("text-xl");
        setTextStyle("text-base");
        setGapBetweenText("gap-y-2");
        setGapChildrenAndText("gap-y-5");
        break;
      default:
        setTitleStyle("text-xl");
        setTextStyle("text-base");
        setGapBetweenText("gap-y-2");
        setGapChildrenAndText("gap-y-5");
        break;
    }
  }, [size]);

  return (
    <div className={emptyStateWrapperCn()}>
      {children && <div className={childrenCn()}>{cloneElement(children, { size: SIZE.lg })}</div>}
      <div className={textAndButtonsCn()}>
        <div className={titleAndTextCn()}>
          <div className={titleCn()}>{title}</div>
          <div className={textCn()}>{text}</div>
        </div>
        {(!!labelButtonGrey?.length || !!labelButtonPrimary?.length) && (
          <div className={buttonsCn()}>
            {!!labelButtonGrey?.length && (
              <ButtonGrey
                size={SIZE.md}
                label={labelButtonGrey}
                onClick={onClickButtonGrey}
                variant={BUTTON_VARIANTS.outline}
              />
            )}
            {!!labelButtonPrimary?.length && (
              <ButtonPrimary
                size={SIZE.md}
                icon={<IconPlus />}
                label={labelButtonPrimary}
                iconPosition={POSITIONS.left}
                onClick={onClickButtonPrimary}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

EmptyState.propTypes = propTypes;
export default EmptyState;
