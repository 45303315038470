import cntl from "cntl";
import { get } from "lodash";
import PropTypes from "prop-types";
import { SELECT_VARIANTS, SIZE } from "../constants";

const propTypes = {
  name: PropTypes.string,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  errors: PropTypes.object,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  e2eSelect: PropTypes.string,
  textHelper: PropTypes.string,
  formRegistration: PropTypes.object,
  options: PropTypes.array.isRequired,
  size: PropTypes.oneOf([SIZE.sm, SIZE.md, SIZE.lg]),
  variant: PropTypes.oneOf([SELECT_VARIANTS.surface]),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

function Select({
  id,
  name,
  label,
  onBlur,
  errors,
  options,
  onChange,
  className,
  e2eSelect,
  isDisabled,
  textHelper,
  value = "",
  size = SIZE.lg,
  formRegistration = null,
  variant = SELECT_VARIANTS.surface,
}) {
  const { onChange: onFormChange, onBlur: onFormBlur, name: formName, ...rest } = formRegistration || {};

  const computedId = id || formName;
  const computedName = name || formName;
  const errorMessage = get(errors, `[${formName}].message`, "");

  const wrapperCn = () => cntl`
    flex
    flex-col
    select-wrapper
    ${className ? className : undefined}
  `;

  const labelCn = () => cntl`
    mb-1
    text-sm
    text-gray-lightAlpha-12
    first-letter:uppercase
    dark:text-gray-darkAlpha-12
  `;

  const textHelpCn = () => cntl`
    mb-1
    text-sm
    first-letter:uppercase
    text-gray-lightAlpha-11
    dark:text-gray-darkAlpha-11
  `;

  const selectCn = ({ hasErrors }) => cntl`
    py-0
    w-full
    border
    form-select
    focus:ring-0
    focus:ring-transparent
    focus:outline-0
    focus:outline-none
    focus:outline-transparent
    ${styleBase[size]?.container?.size}
    ${styleBase[size]?.text?.fontStyle}
    ${styleBase[size]?.container?.rounded}
    ${hasErrors ? styleVariant[variant]?.style?.error : styleVariant[variant]?.style?.default}
  `;

  const errorCn = ({ hasErrors }) => cntl`
    mt-1
    text-sm
    first-letter:uppercase
    text-error-lightAlpha-11
    dark:text-error-darkAlpha-11
    ${!hasErrors && cntl`invisible`}
  `;

  const surfaceVariantBase = () => cntl`
    bg-white/[.9]
    text-gray-light-12
    dark:bg-black/[.25]
    dark:text-gray-dark-12
    disabled:bg-gray-lightAlpha-3
    dark:disabled:bg-gray-darkAlpha-3
    placeholder:text-gray-lightAlpha-10
    dark:placeholder:text-gray-darkAlpha-10
  `;

  const surfaceVariantDefault = () => cntl`
    border-gray-light-6
    dark:border-gray-dark-6
    focus:border-primary-lightAlpha-8
    dark:focus:border-primary-darkAlpha-8
    ${surfaceVariantBase()}
  `;

  const surfaceVariantError = () => cntl`
    border-error-lightAlpha-8
    dark:border-error-darkAlpha-8
    focus:border-error-lightAlpha-8
    dark:focus:border-error-darkAlpha-8
  `;

  const styleBase = {
    [SIZE.sm]: {
      container: {
        size: "h-6",
        rounded: "rounded-0.75",
      },
      text: {
        fontStyle: "text-xs leading-5",
      },
    },
    [SIZE.md]: {
      container: {
        size: "h-8",
        rounded: "rounded",
      },
      text: {
        fontStyle: "text-sm",
      },
    },
    [SIZE.lg]: {
      container: {
        size: "h-10",
        rounded: "rounded-md",
      },
      text: {
        fontStyle: "text-base",
      },
    },
  };

  const styleVariant = {
    [SELECT_VARIANTS.surface]: {
      style: {
        error: surfaceVariantError(),
        default: surfaceVariantDefault(),
      },
    },
  };

  return (
    <div className={wrapperCn()}>
      {label?.length > 0 ? <label className={labelCn()}>{label}</label> : null}
      {textHelper?.length > 0 ? (
        <div className={textHelpCn()} data-cypress="form-help-msg">
          {textHelper}
        </div>
      ) : null}
      <select
        id={computedId}
        name={computedName}
        disabled={isDisabled}
        data-cypress={e2eSelect}
        value={value?.toString()?.toLowerCase()}
        aria-invalid={errorMessage ? "true" : "false"}
        className={selectCn({ hasErrors: !!errorMessage })}
        onChange={(e) => {
          if (onFormChange) {
            onFormChange(e);
          }
          if (onChange) {
            onChange(e);
          }
        }}
        onBlur={(e) => {
          if (onFormBlur) {
            onFormBlur(e);
          }
          if (onBlur) {
            onBlur(e);
          }
        }}
        {...rest}
      >
        {options?.map((option, index) => {
          return (
            <option
              disabled={option?.disabled}
              key={`${option?.value}-${index}`}
              data-attribute={option?.dataAttribute}
              id={`option-${option?.value}-${index}`}
              value={option?.value?.toString()?.toLowerCase()}
            >
              {option?.label}
            </option>
          );
        })}
      </select>
      {errorMessage?.length > 0 ? (
        <div
          className={errorCn({
            hasErrors: !!errorMessage,
          })}
          data-cypress="form-error-msg"
        >
          {errorMessage ? errorMessage : "Error message"}
        </div>
      ) : null}
    </div>
  );
}

Select.propTypes = propTypes;
export default Select;
