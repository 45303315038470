import cntl from "cntl";
import PropTypes from "prop-types";

const propTypes = {
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isRoundedFull: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

function PaginationNumberBase({ value, onClick, isActive, isDisabled = false, isRoundedFull = false }) {
  const wrapperCn = () => cntl`
    w-8
    h-8
    flex
    text-sm
    font-medium
    items-center
    cursor-pointer
    justify-center
    text-gray-light-11
    hover:bg-gray-lightAlpha-4
    hover:text-gray-light-12
    dark:text-gray-dark-11
    dark:hover:bg-gray-darkAlpha-4
    dark:hover:text-gray-dark-12
    ${
      isActive
        ? cntl`bg-gray-lightAlpha-4 text-gray-light-12 dark:bg-gray-darkAlpha-4 dark:text-gray-dark-12`
        : cntl`bg-transparent`
    }
    ${isRoundedFull ? "rounded-full" : "rounded"}
    ${isDisabled ? cntl`pointer-events-none` : cntl`pointer-events-auto	`}
  `;

  return (
    <div className={wrapperCn()} onClick={() => onClick(value)}>
      {value}
    </div>
  );
}

PaginationNumberBase.propTypes = propTypes;
export default PaginationNumberBase;
