import cntl from "cntl";
import PropTypes from "prop-types";
import { FeaturedIcon } from "@dbox/components";
import { COLOR, FEATURED_ICON_VARIANTS, SIZE } from "@dbox/components/constants";

const propTypes = {
  icon: PropTypes.object,
  title: PropTypes.string,
  className: PropTypes.string,
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

function VerificationTitleAndSubtitle({ icon, title, subTitle, className }) {
  const wrapperCn = () => cntl`
    flex
    gap-y-6
    flex-col
    items-center
    ${className ? className : undefined}
  `;

  const titleAndSubTitleCn = () => cntl`
    flex
    gap-y-3
    flex-col
    text-center
    items-center
  `;

  const titleCn = () => cntl`
    text-3xl
    font-semibold
    text-gray-light-12
    dark:text-gray-dark-12
    first-letter:uppercase
  `;

  const subTitleCn = () => cntl`
    text-base
    text-gray-light-11
    dark:text-gray-dark-11
    first-letter:uppercase
  `;

  return (
    <div className={wrapperCn()}>
      <FeaturedIcon
        size={SIZE.xl}
        variant={FEATURED_ICON_VARIANTS.square}
        color={COLOR.gray}
      >
        {icon}
      </FeaturedIcon>
      <div className={titleAndSubTitleCn()}>
        <div className={titleCn()}>{title}</div>
        <div className={subTitleCn()}>{subTitle}</div>
      </div>
    </div>
  );
}

VerificationTitleAndSubtitle.propTypes = propTypes;
export default VerificationTitleAndSubtitle;
