import PropTypes from "prop-types";

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

function Header({ children, ...props }) {
  return <header {...props}>{children}</header>;
}

Header.propTypes = propTypes;
export default Header;
