import cntl from "cntl";
import PropTypes from "prop-types";

// Hooks
import { useMemo } from "react";
import { usePagination } from "@dbox/core/hooks/usePagination";
import { useWindowSize } from "@dbox/core/hooks/useWindowSize";

// Components
import PaginationCenter from "./PaginationCenter";

// Constants
import { BREAKPOINT, PAGINATION_VARIANTS } from "../../constants";

const propTypes = {
  total: PropTypes.number,
  goToPage: PropTypes.func,
  nextPage: PropTypes.func,
  pageCount: PropTypes.number,
  pageIndex: PropTypes.number,
  canNextPage: PropTypes.bool,
  previousPage: PropTypes.func,
  canPreviousPage: PropTypes.bool,
  variant: PropTypes.oneOf([PAGINATION_VARIANTS.center, PAGINATION_VARIANTS.default]),
};

function isMobile(screenSize) {
  if (screenSize >= BREAKPOINT.md) {
    return false;
  } else {
    return true;
  }
}

function Pagination({
  total,
  goToPage,
  nextPage,
  pageCount,
  pageIndex,
  canNextPage,
  previousPage,
  canPreviousPage,
  siblingCount = 1,
  paginationSize = 15,
  variant = PAGINATION_VARIANTS.center,
}) {
  const { width } = useWindowSize();
  const paginationRange = usePagination({
    total,
    pageIndex,
    siblingCount,
    paginationSize,
  });

  const pagesInfo = useMemo(
    () => ({
      lastPage: pageCount,
      currentPage: pageIndex,
    }),
    [pageCount, pageIndex]
  );

  const paginationData = useMemo(
    () => ({
      canNextPage,
      canPreviousPage,
      paginationRange,
    }),
    [canNextPage, canPreviousPage, paginationRange]
  );

  const paginationCn = () => cntl`
    ${style[variant].border}
    ${style[variant].padding}
  `;

  const style = {
    [PAGINATION_VARIANTS.center]: {
      padding: "pt-2 sm:pt-5 px-4 sm:px-6",
      border: "border-t border-t-gray-lightAlpha-6 dark:border-t-gray-darkAlpha-6",
    },
    [PAGINATION_VARIANTS.default]: {
      border: "border-t-none",
      padding: "py-3 px-4 sm:pb-4 sm:px-6",
    },
  };

  const renderPaginationProps = {
    variant,
    goToPage,
    nextPage,
    pagesInfo,
    previousPage,
    isMobile: isMobile(width),
    paginationProps: paginationData,
  };

  return (
    <div className={paginationCn()}>
      <RenderPagination {...renderPaginationProps} />
    </div>
  );
}

const RenderPagination = ({ variant, goToPage, nextPage, pagesInfo, isMobile, previousPage, paginationProps }) => {
  const paginationCenterProps = {
    goToPage,
    nextPage,
    pagesInfo,
    isMobile,
    previousPage,
    paginationProps,
  };

  switch (variant) {
    case PAGINATION_VARIANTS.center:
    case PAGINATION_VARIANTS.default:
      return <PaginationCenter {...paginationCenterProps} />;
    default:
      return <PaginationCenter {...paginationCenterProps} />;
  }
};

Pagination.propTypes = propTypes;
export default Pagination;
