export const DOTS = "...";

export const PAGE_SIZE = 15;

export const BREAKPOINT = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  "2xl": 1536,
};

export const ADDRESS_TYPES = {
  legal: "legal",
  billing: "billing",
  shipping: "shipping",
  residential: "residential",
};

export const CUSTOMER_TYPES = {
  corporate: "corporate",
  individual: "individual",
};

export const PROFILE_ROLE = {
  administrator: "administrator",
  technician: "technician",
  commercial: "commercial",
};

export const TENANT_TYPES = {
  typography: "typography",
  web_agency: "web_agency",
};

export const FORMAT_ACCEPTED = {
  json: "json",
  image: "image",
  document: "document",
};

export const IMAGE_UPLOADER_VARIANT = {
  square: "square",
  rounded: "rounded",
};

export const AVATAR_VARIANT = {
  square: "square",
  rounded: "rounded",
};

export const AVATAR_STATUS = {
  online: "online",
  offline: "offline",
};

export const KEYBOARDS = {
  enter: 13,
  backspace: 8,
  arrowLeft: 37,
  arrowRight: 39,
};

export const STATUS_VALUES = {
  ENABLED: "enabled",
  DISABLED: "disabled",
};

export const dimensions = {
  SMALL: "small",
  DEFAULT: "default",
  LARGE: "large",
};

export const formatValues = {
  GENERAL: "general",
  SINGLE: "single",
  PAGES: "pages",
  SUPPORT: "support",
  COVER: "cover",
  FRONT_COVER: "frontcover",
  BACK_COVER: "backcover",
};

export const PickerPositions = {
  TOP: "top",
  BOTTOM: "bottom",
};

export const DIVIDER_TONE = {
  dark: "dark",
  light: "light",
};

export const SIZE = {
  xxs: "xxs",
  xs: "xs",
  sm: "sm",
  md: "md",
  lg: "lg",
  xl: "xl",
  base: "base",
  "2xl": "2xl",
  "3xl": "3xl",
  "4xl": "4xl",
  "w-full": "w-full",
  floating: "floating",
};

export const WIDTH = {
  xs: "w-20",
  sm: "w-40",
  md: "w-60",
  lg: "w-80",
  xl: "w-[35rem]",
};

export const COLOR = {
  gray: "gray",
  dark: "dark",
  info: "info",
  pink: "pink",
  blue: "blue",
  amber: "amber",
  light: "light",
  error: "error",
  primary: "primary",
  warning: "warning",
  success: "success",
  primary_light: "primaryLight",
};

export const COLOR_PICKER_VARIANTS = {
  circle: "circle",
};

export const PROGRESS_BAR_VARIANTS = {
  circular: "circular",
  linear: "linear",
};

export const STEP_ICON_BASE_VARIANTS = {
  mid: "mid",
  dark: "dark",
  light: "light",
};

export const CHECK_ITEM_ICON_VARIANTS = {
  dark: "dark",
  light: "light",
  border: "border",
};

export const STEP_ICON_BASE_STATE = {
  current: "current",
  complete: "complete",
  incomplete: "incomplete",
};

export const STEP_DIRECTION = {
  vertical: "vertical",
  horizontal: "horizontal",
};

export const ORIENTATION = {
  vertical: "vertical",
  horizontal: "horizontal",
};

export const BADGE_ICON_VARIANTS = {
  custom: "custom",
  only_icon: "onlyIcon",
};

export const BADGE_VARIANTS = {
  soft: "soft",
  solid: "solid",
  outline: "outline",
  surface: "surface",
};

export const BUTTON_VARIANTS = {
  soft: "soft",
  ghost: "ghost",
  solid: "solid",
  outline: "outline",
};

export const FOOTER_VARIANTS = {
  only_link: "onlyLink",
  sidesLink: "sidesLink",
};

export const FOOTER_COLOR_VARIANTS = {
  dark: "dark",
  gray: "gray",
  primary: "primary",
  white: "white",
};

export const FEATURED_ICON_VARIANTS = {
  dark: "dark",
  square: "square",
  light_circle: "lightCircle",
  light_circle_outline: "lightCircleOutline",
};

export const LINK_VARIANTS = {
  normal: "font-normal",
  medium: "font-medium",
  semibold: "font-semibold",
  bold: "font-bold",
};

export const HEADER_PRICING = {
  with_featured_icon: "withFeaturedIcon",
};

export const ONBOARDING_STATE = {
  init: "init",
  completed: "completed",
  processing: "processing",
};

export const SELECT_VARIANTS = {
  surface: "surface",
};

export const TEXTAREA_VARIANTS = {
  surface: "surface",
};

export const INPUT_VARIANTS = {
  surface: "surface",
};

export const NAV_MENU_HEADER_VARIANTS = {
  only_cols: "onlyCols",
  only_images: "onlyImages",
  three_cols_one_card: "threeColsOneCard",
  two_cols_multiple_cards: "twoColsMulpileCards",
};

export const BREADCRUMB_VARIANTS = {
  text: "text",
  gray: "gray",
  primary: "primary",
  simple: "simple",
};

export const TAB_BUTTON_VARIANTS = {
  gray: "gray",
  white: "white",
  primary: "primary",
  underline: "underline",
  underline_filled: "underlineFilled",
};

export const MODAL_HEADER_VARIANTS = {
  left: "left",
  center: "center",
  inline: "inline",
};

export const MODAL_ACTION_VARIANTS = {
  row: "row",
  column: "column",
  other_actions: "otherActions",
};

export const CARD_MENU_ITEM_CARD_VARIANTS = {
  feature: "feature",
  image_card: "imageCard",
  feature_card: "featureCard",
};

export const POSITIONS = {
  left: "left",
  right: "right",
  center: "center",
};

export const DIRECTION = {
  row: "row",
  column: "column",
};

export const SELF_ALIGNMENT = {
  start: "self-start",
  center: "self-center",
  end: "self-end",
};

export const TEXT_ALIGNMENT = {
  left: "text-left",
  center: "text-center",
  right: "text-right",
};

export const PAGE_MODES = {
  create: "create",
  update: "update",
};

export const STATUS = {
  error: "error",
  completed: "completed",
  undefined: "undefined",
  success: "success",
  pending: "pending",
  confirmed: "confirmed",
  denied: "denied",
  active: "active",
  disabled: "disabled",
  enabled: "enabled",
};

export const PAGINATION_VARIANTS = {
  center: "center",
  default: "default",
};

export const PICKER_MONTH = {
  first_month: "firstMonth",
  second_month: "secondMonth",
};

export const PICKER_VARIANT = {
  data_range_base: "dataRangeBase",
  data_range_complex: "dataRangeComplex",
};

export const NAV_ITEM_BASE_VARIANTS = {
  main: "main",
  sub_main: "subMain",
};

export const SCOPE = {
  scope_1: "scope_1",
  scope_2: "scope_2",
  scope_3: "scope_3",
};

export const NOTIFICATION_VARIANTS = {
  toast: "toast",
  inline: "inline",
};

export const NOTIFICATION_TYPES = {
  info: "info",
  error: "error",
  success: "success",
  warning: "warning",
};

export const SKELETON_VARIANTS = {
  row: "row",
  card: "card",
  table: "table",
  rectangle: "rectangle",
  sub_header: "sub_header",
};

export const PRODUCT_TYPE = {
  external: "external",
  sellable: "sellable",
  cooming_soon: "coming_soon",
};
