import { get } from "lodash";
import { createSlice } from "@reduxjs/toolkit";

export const defaultState = {
  error: null,
  accessToken: null,
  loginVerification: { step1: false, step2: false },
};

const authSlice = createSlice({
  name: "auth",
  initialState: defaultState,
  reducers: {
    setLoginVerification(state, action) {
      state.loginVerification.step2 = action.payload.step2;
    },
    removeTokens: (state) => {
      return {
        ...state,
        error: null,
        accessToken: null,
        loginVerification: { step1: false, step2: false },
      };
    },
  },
  extraReducers: {
    "auth/syncTokens/fulfilled": (state, { payload }) => {
      return {
        ...state,
        accessToken: payload,
      };
    },
    "auth/syncTokens/rejected": (state, { payload }) => {
      return {
        ...state,
        accessToken: null,
        error: payload,
      };
    },
    "auth/userMe/fulfilled": (state, { payload }) => {
      return {
        ...state,
        user: payload,
        loginVerification: { step1: true, step2: true },
      };
    },
    "auth/userMe/rejected": (state, { payload }) => {
      return {
        ...state,
        error: payload,
      };
    },
    "auth/refresh/fulfilled": (state, { payload }) => {
      return {
        ...state,
        accessToken: payload,
        loginVerification: { step1: true, step2: true },
      };
    },
    "auth/refresh/rejected": (state, { payload }) => {
      return {
        ...state,
        error: payload,
      };
    },
    "auth/logIn/fulfilled": (state, { payload }) => {
      const accessToken = get(payload, "access_token", "");
      const profile = get(payload, "profile", null);
      const registration = get(payload, "registration", null);
      const tenant = get(payload, "tenant", null);

      return {
        ...state,
        accessToken,
        user: { profile, registration, tenant },
        loginVerification: { step1: true, step2: false },
      };
    },
    "auth/logIn/rejected": (state, { payload }) => {
      return {
        ...state,
        error: payload,
      };
    },
    "auth/logOut/fulfilled": (state) => {
      return {
        ...state,
        user: null,
        accessToken: null,
        loginVerification: { step1: false, step2: false },
      };
    },
    "auth/logOut/rejected": (state) => {
      return {
        ...state,
        user: null,
        accessToken: null,
        loginVerification: { step1: false, step2: false },
      };
    },
  },
});

export const getLoginVerificationState = (state) => state.loginVerification;
export const getIsLoggedIn = (state) => state.accessToken !== null;
export const getAccessToken = (state) => state.accessToken;
export const getUser = (state) => state.user;
export const getIsRegistrationCompleted = (state) => {
  if (!getIsLoggedIn(state)) {
    return null;
  }
  const userTenantObj = get(state, "user.tenant", {});
  return userTenantObj?.status_registration === 1;
};

export default authSlice;
