function IconWithTitle({ icon, text = "", className }) {
  return (
    <div
      className={`flex flex-row gap-x-2 items-center ${
        className ? className : undefined
      }`}
    >
      <>{icon}</>
      <div className="text-lg text-gray-light-11 dark:text-gray-dark-11 font-medium capitalize">
        {text}
      </div>
    </div>
  );
}

export default IconWithTitle;
