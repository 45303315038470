import store from "@dbox/aldys-common/store";
import { logOut } from "@dbox/core/actions/aldys/auth";
import { createHttpClient, localStorage } from "@dbox/core";
import { getAccessToken } from "@dbox/aldys-common/store/reducers/app.reducer";

export default createHttpClient({
  storage: localStorage,
  getToken: () => getAccessToken(store.getState()),
  baseURL: process.env.REACT_APP_API_URL,
  logOut: () => store.dispatch(logOut()),
});
