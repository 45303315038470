function LogoMarkAldysWhite({ width = "95", height = "32" }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 95 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M20 8H12V32H20V8Z" fill="#966440" />
      <path d="M20 16H12V24H20V16Z" fill="#09A129" />
      <path
        d="M20 16L25.6576 10.3424L28 8H20C17.8783 8 15.8434 8.84285 14.3431 10.3431C12.8429 11.8434 12 13.8783 12 16H20Z"
        fill="#27FB6B"
      />
      <path
        d="M28 16V8L25.6576 10.3424L20 16V24C22.1217 24 24.1566 23.1571 25.6569 21.6568C27.1571 20.1566 28 18.1217 28 16Z"
        fill="#036D19"
      />
      <path
        d="M12 8L6.3424 2.3424L4 0V8C4 10.1217 4.84285 12.1566 6.34314 13.6568C7.84343 15.1571 9.87826 16 12 16V8Z"
        fill="#036D19"
      />
      <path
        d="M12 0H4L6.3424 2.3424L12 8H20C20 5.87826 19.1571 3.84343 17.6568 2.34314C16.1566 0.842854 14.1217 0 12 0V0Z"
        fill="#27FB6B"
      />
      <g clipPath="url(#clip0_141_158516)">
        <path
          d="M37 24.2093L43.8063 7.53683H46.4759L53.2582 24.2093H49.7708L44.5278 10.5251H45.7303L40.4152 24.2093H37ZM40.6076 21.1025V18.3989H49.6987V21.1025H40.6076Z"
          fill="white"
        />
        <path
          d="M54.7455 24.2093V7.0625H57.8962V24.2093H54.7455Z"
          fill="white"
        />
        <path
          d="M65.2333 24.4465C64.159 24.4465 63.197 24.1856 62.3472 23.6639C61.4974 23.1421 60.824 22.4306 60.327 21.5294C59.846 20.6282 59.6054 19.6163 59.6054 18.4937C59.6054 17.3553 59.846 16.3355 60.327 15.4343C60.824 14.5331 61.4894 13.8216 62.3232 13.2999C63.1729 12.7781 64.143 12.5172 65.2333 12.5172C66.067 12.5172 66.8126 12.6832 67.47 13.0153C68.1434 13.3473 68.6805 13.8137 69.0814 14.4145C69.4982 14.9995 69.7227 15.6636 69.7548 16.4067V20.4859C69.7227 21.229 69.5063 21.9009 69.1054 22.5018C68.7046 23.1026 68.1674 23.5769 67.494 23.9247C66.8206 24.2726 66.067 24.4465 65.2333 24.4465ZM65.7624 21.5768C66.3556 21.5768 66.8687 21.4503 67.3016 21.1974C67.7506 20.9286 68.0953 20.5649 68.3358 20.1064C68.5923 19.6321 68.7206 19.0866 68.7206 18.47C68.7206 17.8692 68.5923 17.3395 68.3358 16.881C68.0953 16.4067 67.7506 16.043 67.3016 15.7901C66.8687 15.5213 66.3637 15.3869 65.7864 15.3869C65.1932 15.3869 64.6721 15.5213 64.2232 15.7901C63.7902 16.0589 63.4455 16.4225 63.189 16.881C62.9485 17.3395 62.8282 17.8692 62.8282 18.47C62.8282 19.0866 62.9485 19.6321 63.189 20.1064C63.4455 20.5649 63.7983 20.9286 64.2472 21.1974C64.6961 21.4503 65.2012 21.5768 65.7624 21.5768ZM71.6788 24.2093H68.5523V21.1262L69.0573 18.3277L68.5282 15.5766V7.0625H71.6788V24.2093Z"
          fill="white"
        />
        <path
          d="M77.761 24.3042L72.9028 12.7544H76.318L79.5407 21.5768H78.3863L81.7293 12.7544H85.1686L79.9977 24.2805L77.761 24.3042ZM74.5382 29L78.0736 21.6243L79.9977 24.2805L77.8812 29H74.5382Z"
          fill="white"
        />
        <path
          d="M89.9736 24.4702C89.3162 24.4702 88.6668 24.3832 88.0255 24.2093C87.4002 24.0354 86.823 23.7982 86.2939 23.4978C85.7647 23.1816 85.3078 22.8022 84.923 22.3595L86.7989 20.4859C87.1998 20.9286 87.6647 21.2606 88.1939 21.482C88.723 21.7033 89.3082 21.814 89.9495 21.814C90.4626 21.814 90.8474 21.7428 91.104 21.6005C91.3765 21.4582 91.5128 21.2448 91.5128 20.9602C91.5128 20.644 91.3685 20.3989 91.0799 20.225C90.8073 20.0511 90.4466 19.9088 89.9976 19.7981C89.5487 19.6716 89.0757 19.5372 88.5787 19.3949C88.0976 19.2368 87.6327 19.0392 87.1837 18.802C86.7348 18.5491 86.366 18.2091 86.0774 17.7822C85.8048 17.3395 85.6685 16.7703 85.6685 16.0747C85.6685 15.3474 85.8449 14.7149 86.1977 14.1774C86.5664 13.6398 87.0875 13.2208 87.7609 12.9204C88.4344 12.62 89.228 12.4698 90.1419 12.4698C91.104 12.4698 91.9698 12.6358 92.7394 12.9678C93.5251 13.2999 94.1744 13.7979 94.6875 14.462L92.7875 16.3355C92.4348 15.9086 92.0339 15.6003 91.585 15.4106C91.1521 15.2209 90.6791 15.126 90.166 15.126C89.701 15.126 89.3403 15.1972 89.0837 15.3395C88.8432 15.4818 88.723 15.6794 88.723 15.9324C88.723 16.217 88.8592 16.4383 89.1318 16.5964C89.4204 16.7545 89.7892 16.8968 90.2381 17.0233C90.6871 17.134 91.1521 17.2684 91.6331 17.4265C92.1301 17.5688 92.5951 17.7743 93.028 18.0431C93.477 18.3119 93.8377 18.6676 94.1103 19.1103C94.3989 19.553 94.5432 20.1222 94.5432 20.8179C94.5432 21.9405 94.1343 22.8338 93.3166 23.4978C92.4989 24.1461 91.3846 24.4702 89.9736 24.4702Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_141_158516">
          <rect
            width="57.6875"
            height="26"
            fill="white"
            transform="translate(37 3)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default LogoMarkAldysWhite;
