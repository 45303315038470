import cntl from "cntl";
import PropTypes from "prop-types";

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};

function MenuBlock({ children }) {
  const menuBlockCn = () => cntl`
    p-1.5
    border-t
    first:border-t-0
    border-gray-light-6
    dark:border-gray-dark-6
  `;

  return <div className={menuBlockCn()}>{children}</div>;
}

MenuBlock.propTypes = propTypes;
export default MenuBlock;
